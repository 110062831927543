"use client";

import Picture1 from "@/public/png/common/view-more/customer-1.jpg";
import Picture2 from "@/public/png/common/view-more/customer-2.jpg";
import Picture3 from "@/public/png/common/view-more/customer-3.jpg";
import Picture4 from "@/public/png/common/view-more/customer-4.jpg";
import Picture5 from "@/public/png/common/view-more/customer-5.jpg";
import Picture6 from "@/public/png/common/view-more/customer-6.jpg";
import Picture7 from "@/public/png/common/view-more/customer-7.jpg";
import Picture8 from "@/public/png/common/view-more/customer-8.jpg";
import Picture9 from "@/public/png/common/view-more/customer-9.jpg";
import Picture10 from "@/public/png/common/view-more/customer-10.jpg";
import Picture11 from "@/public/png/common/view-more/customer-11.jpg";
import Picture12 from "@/public/png/common/view-more/customer-12.jpg";
import Picture13 from "@/public/png/common/view-more/customer-13.jpg";
import Picture14 from "@/public/png/common/view-more/customer-14.jpg";
import Picture15 from "@/public/png/common/view-more/customer-15.jpg";
import Picture16 from "@/public/png/common/view-more/customer-16.jpg";
import Picture17 from "@/public/png/common/view-more/customer-17.jpg";
import Picture18 from "@/public/png/common/view-more/customer-18.jpg";
import Picture19 from "@/public/png/common/view-more/customer-19.jpg";
import Picture20 from "@/public/png/common/view-more/customer-20.jpg";
import Picture21 from "@/public/png/common/view-more/customer-21.jpg";
import Picture22 from "@/public/png/common/view-more/customer-22.jpg";
import Picture23 from "@/public/png/common/view-more/customer-23.jpg";
import Picture24 from "@/public/png/common/view-more/customer-24.jpg";
import Picture25 from "@/public/png/common/view-more/customer-25.jpg";
import Picture26 from "@/public/png/common/view-more/customer-26.jpg";
import Picture27 from "@/public/png/common/view-more/customer-27.jpg";
import Picture28 from "@/public/png/common/view-more/customer-28.jpg";
import Picture29 from "@/public/png/common/view-more/customer-29.jpg";
import Picture30 from "@/public/png/common/view-more/customer-30.jpg";





import Button from "@/components/button/Button";
import Heading from "@/components/heading/heading";

import "@/styles/common/typo.scss";
import "./view-more.scss";

import Image from "next/image";
import Marquee from "react-fast-marquee";

const ViewMore = ({ pageName = "default" }) => {

    const ViewMoreList1 = [
        {
            picture: Picture1,
        },
        {
            picture: Picture2,
        },
        {
            picture: Picture3,
        },
        {
            picture: Picture4,
        },
        {
            picture: Picture5,
        },
        {
            picture: Picture6,
        },
        {
            picture: Picture7,
        },
        {
            picture: Picture8,
        },
        {
            picture: Picture9,
        },
        {
            picture: Picture10,
        },
        {
            picture: Picture11,
        },
        {
            picture: Picture12,
        },
        {
            picture: Picture13,
        },
        {
            picture: Picture14,
        },
        {
            picture: Picture15,
        },

    ];
    const ViewMoreList2 = [
        {
            picture: Picture16,
        },
        {
            picture: Picture17,
        },
        {
            picture: Picture18,
        },
        {
            picture: Picture19,
        },
        {
            picture: Picture20,
        },
        {
            picture: Picture21,
        },
        {
            picture: Picture22,
        },
        {
            picture: Picture23,
        },
        {
            picture: Picture24,
        },
        {
            picture: Picture25,
        },
        {
            picture: Picture26,
        },
        {
            picture: Picture27,
        },
        {
            picture: Picture28,
        },
        {
            picture: Picture29,
        },
        {
            picture: Picture30,
        },
    ];
    return (
        <section className={`view-more-section flex flex-col gap-12 ${pageName == "about-us" ? "pt-20" : ""}`}>
            <Marquee direction="right" className="marquee-list">
                {[...ViewMoreList1, ...ViewMoreList1].map((v2, index) => {
                    return (
                        <div
                            key={index}
                            className="view-more-slide marquee-list-item"
                        >
                            <figure>
                                <Image loading="lazy"
                                    src={v2.picture}
                                    alt="picture"
                                />
                            </figure>
                        </div>
                    );
                })}
            </Marquee>
            <div className="view-more-block flex-center flex-wrap gap-2 md:gap-6 px-4">
                <Heading as="h2">Happy Customers, Happy Stories</Heading>
                <Button onClick={() => window.open('https://www.instagram.com/dimaaktours/', '_blank')} className="flex items-center" variant="orange">
                    <span>View Instagram</span>
                </Button>
            </div>
            <Marquee className="marquee-list">
                {[...ViewMoreList2, ...ViewMoreList2].map((v2, index) => {
                    return (
                        <div
                            key={index}
                            className="view-more-slide marquee-list-item"
                        >
                            <figure>
                                <Image loading="lazy"
                                    src={v2.picture}
                                    alt="picture"
                                    
                                />
                            </figure>
                        </div>
                    );
                })}
            </Marquee>
        </section>
    );
};

export default ViewMore;
